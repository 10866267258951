import {
  Loading,
  Toast,
  Cell,
  NavBar,
  Skeleton,
  Search,
  Image,
  ImagePreview,
  Steps,
  Step,
  Form,
  Field,
  Popup,
  Picker,
  Uploader,
  Icon,
  Overlay,
  Button,
  List,
  Row,
  Col,
  Empty,
  PullRefresh
} from 'vant'

export const components = [
  Loading,
  Toast,
  Cell,
  NavBar,
  Skeleton,
  Search,
  Image,
  ImagePreview,
  Steps,
  Step,
  Form,
  Field,
  Popup,
  Picker,
  Uploader,
  Icon,
  Overlay,
  Button,
  List,
  Row,
  Col,
  Empty,
  PullRefresh
]
