import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 不同浏览器一些默认的html标签的展示行为是不一致的，normalize.css 使样式在所有浏览器上保持一致
import 'normalize.css'
import '@/style/index.scss'

import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html'
import '@kangc/v-md-editor/lib/style/preview-html.css'

// 引入使用主题的样式
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import '@kangc/v-md-editor/lib/theme/style/github.css'

import 'vant/es/image-preview/style'

import Vconsole from 'vconsole'

import { components } from './plugins/vant'

process.env.VUE_APP_TITLE !== 'prod' && new Vconsole()

const app = createApp(App)
components.forEach((component: any) => {
  app.component(component.name, component)
})

app.use(VMdPreviewHtml)
app.use(store).use(router).mount('#app')
